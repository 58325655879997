<template>
    <div v-if="gallery && typeof (gallery.media) != 'undefined'" class="slider__wrapper">
        <div class="slideControls d-none d-md-block">
            <a class="slidePrev slider__control" @click="prevSlide">
                <i class="icon icon-left-open"></i>
            </a>
            <a class="slideNext slider__control" @click="nextSlide">
                <i class="icon icon-right-open"></i>
            </a>
        </div>

        <slick
            ref="slick"
            :options="slickOptions"
         >

            <div v-for="(tmpImage, imgIndex) in Object.values(gallery.media)" v-bind:key="imgIndex">
                <img :src="beUrl + tmpImage.src" alt="" />
            </div>
        </slick>
    </div>
</template>

<script>
    import Slick from 'vue-slick';
    export default {
        name: 'ImageSlider',
        props: ['gallery'],
        components: { Slick },
        data: function() {
            return {
                sliderInit: false,
                slickOptions: {
                    variableWidth: true,
                    slidesToShow: this.checkIfMobile() ? 1 : 3,
                    slidesToScroll: 2,
                    prevArrow: window.jQuery('.slidePrev'),
                    nextArrow: window.jQuery('.slideNext'),
                    dots: false,
                    focusOnSelect: true,
                    centerMode: false, // breaks functionality
                    centerPadding: '5px',
                    infinite: true,
                },
                beUrl: process.env.VUE_APP_BE_URL
            };
        },
        methods: {
            nextSlide() {
                this.$refs.slick.next();
            },
            prevSlide() {
                this.$refs.slick.prev();
            },
            reInit() {
                this.$nextTick(() => {
                    this.$refs.slick.reSlick();
                });
            }
        },
        updated() {
            this.reInit()
        }
    };
</script>
<style lang="scss">
    @import '../../node_modules/slick-carousel/slick/slick.scss';
    @import "./src/scss/slick-theme.scss";
</style>
